<template>
  <div class="container container_policy flex">
    <div class="left">
      <h1>惠农政策</h1>
      <ul>
        <li :class="index==idx?'active':''"
            v-for="(item,idx) in list_label"
            :key="item.id"
            @click="son(item,idx)">{{item.labelName}}</li>
      </ul>
    </div>
    <div class="right">
      <div>
        <h1>{{labelName}}</h1>
      </div>
      <div>
        <ul>
          <li class="flex"
              v-for="(item,idx) in list"
              :key="idx"
              v-show="list.length">
            <p class="textoverstep" @click="son2(item,index)">{{item.title}}</p>
            <p>{{item.createTime}}</p>
          </li>
          <li class="flex"
              v-show="list.length == 0">
            <p class="noData">暂时无数据</p>
          </li>
        </ul>
      </div>
       <div class="Pagination">
        <ul class="flex">
          <li v-if="cur>1"><a @click="cur--,pageClick()">上一页</a></li>
          <li v-if="cur==1"><a class="banclick">上一页</a></li>
          <li v-for="idx in indexs" :key="idx" :class="{ 'active': cur == idx}">
          <a @click="btnClick(idx)">{{ idx }}</a>
          </li>
          <li v-if="cur!=all"><a @click="cur++,pageClick()">下一页</a></li>
          <li v-if="cur == all"><a class="banclick">下一页</a></li>
          <li><a>共<i>{{all}}</i>页</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { getRuralFinanceList } from '@/api/ruralFinance'
import { getPolicyContent } from '@/api/BenefitfarmersPolicy'
import { getNavigationList } from '@/api/about'

export default {
  name: 'peasantryPolicy',
  data() {
    return {
      list_label: [],
      list: '',
      index: 0,
      labelName: '',
      lblid: '',
      isshow:false,
      leftIndex:'',
      labelObj:{
        id:''
      },
      all: 10, //总页数
      cur: 1, //当前页码
      totalPage: 0, //当前条数
    }
  },
  methods: {
    son(item, index) {
      this.index = index
      this.lblid = item.id
      this.getpolicyContent(item.id)
      if (this.list_label[index] != undefined) {
        this.labelName = this.list_label[index].labelName
      }

    },
    son2(item,index) {
      let columnNameId = this.list_label[index].columnId
      let routerJump=this.$router.resolve({
          path: '/domesticDetails',
          query: {
            titleId:item.id,
            // columnNameId,
          }
      })
      window.open(routerJump.href, '_blank')
    },
    getAgtID () {
      getNavigationList({
        parentId: 0
      }).then((res) => {
        res.data.forEach(element => {
          if (element.columnName == "惠农政策") {
            this.getCatalogueList(element.id)
          }
        });
      })
    },
    // 获取标签名称
    getCatalogueList(inforId) {
      const columnId = inforId
      getRuralFinanceList({ columnId }).then((res) => {
        this.list_label = res.data
        this.lblid = this.list_label[0].id
        if (this.labelObj.id != undefined) {
            for (let index = 0; index <  res.data.length; index++) {
              if (res.data[index].id == this.labelObj.id) {
                this.leftIndex = index
              }
            }
      }
        if (this.labelName === '') {
          this.labelName = this.list_label[0].labelName
        }
        this.getpolicyContent(this.lblid,this.cur.toString())
      })
    },
    // 获取标签内容
    getpolicyContent(id,idx) {
      getPolicyContent({
        labelId: id,
        size:15,
        current:idx
        }).then((res) => {
        this.list=res.data.records
        this.all=res.data.pages
        this.cur=res.data.current
        if(this.list.length){
          this.isshow=true
        }else{
          this.isshow=false
        }
      if (this.labelObj.id != undefined) {
        this.son(this.labelObj, this.leftIndex)
        this.labelObj.id=''
      }
      })
    },
     dataList (id,idx) {
      this.getpolicyContent(id,idx)
    },
    btnClick(data){
      if(data != this.cur){ //判断是不是当前页，不是就计算
        this.cur = data
      }
      this.dataList(this.lblid,this.cur.toString())
    },
    pageClick () {
      this.dataList(this.lblid,this.cur.toString())
    },
    //点击更改头部标签
    selectChangeTitle(idx) {

    },
  },
  mounted() {
    this.labelObj.id = this.$route.query.labelId
    this.getAgtID()
  },
    computed: {
    //上一张
    indexs() {
        var left = 1; //默认起始值是1
        var right = this.all; //终止值是全部
        var arr = [];
        if(this.all>= 5){
            if(this.cur > 3 && this.cur < this.all-2){
                    left = this.cur - 2
                    right = this.cur + 2
            }else{
                if(this.cur<=3){
                    left = 1
                    right = 5
                }else{
                    right = this.all
                    left = this.all -4
                }
            }
        }
        while (left <= right){
            arr.push(left)
            left ++
        }
        return arr
    }
  }
}
</script>
<style scoped>
@import '../../assets/css/base.css';
.container_policy {
  margin-top: 20px;
}

.left {
  width: 230px;
  font-size: 16px;
  padding: 0 10px;
  margin: 10px 0;
  height: 100%;
  min-height: 300px;
  text-align: left;
  padding: 20px;
  box-shadow: 10px -7px 12px -6px rgba(0, 0, 0, 0.1);
}
.left h1 {
  font-size: 20px;
  color: #93b067;
  margin-bottom: 12px;
}

.left .active {
  background-color: #c8e5b8;
  width: 108%;
  color: #666;
}
.left li {
  color: #666;
  padding: 10px 0px 10px 13px;
  font-size: 14px;
  margin-bottom: 10px;
  cursor: pointer;
}
.right {
  padding-left: 25px;
  width: 100%;
  margin: 10px 0 0 15px;
  margin-top: 10px;
  line-height: 26px;
}

.right h1 {
  font-weight: normal;
  position: relative;
  text-align: left;
  margin-bottom: 10px;
}
.right h1::before {
  content: '';
  width: 5px;
  height: 20px;
  display: inline-block;
  background: #93b067;
  margin-right: 15px;
}
.right > div:nth-child(2) {
  padding: 0 20px;
}
.right > div:nth-child(2) li {
  justify-content: space-between;
  padding: 10px 0;
  width: 900px;
}
.right > div:nth-child(2) li p:nth-child(1) {
  width: 78%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}
.right .active {
  color: #63c8f1;
  text-decoration: underline;
}
.right div:nth-child(2) li:hover{
    color: #51d5c3;
}
.textoverstep{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.noData{
  color: rgb(136, 134, 134);
  text-align: center !important;
  width: 100% !important;
}
.Pagination{
  margin: 40px auto;
  margin-top:50px;
}
.Pagination ul{
  justify-content: center;
}
.Pagination li:first-child>a{
  margin-left: 0px;
}
.Pagination a{
  border: 1px solid #000;
  text-decoration: none;
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  color: #5d6062;
  cursor: pointer;
  margin-right: 20px;
}
.Pagination a:hover{
  background-color: #eee;
}
.Pagination a.banclick{
  cursor: not-allowed;
}
.Pagination .active a{
  color: #fff;
  cursor: default;
  background-color: #51d5c3;
  border-color: #51d5c3;
}
.Pagination i{
  font-style: normal;
  color: #51d5c3;
  margin: 0px 4px;
  font-style: 12px;
}
.right li {
  cursor: pointer !important;
}
</style>
